import { ListItemClick } from '@/assets/sounds/soundsEffects';
import globalData from '@/data/global.json';
import Button from '@/features/devices/tablets/components/buttons/Button';
import ClickableItem from '@/features/devices/tablets/components/list/components/ClickableItem';
import styles from '@/features/devices/tablets/components/list/risksOrLeversList.module.scss';
import {
    handleUpdateRiskOrLeverStatus,
    handleValidateGame1RiskOrLever,
} from '@/services/games/game1/gameOneHandleFunctions';
import {
    getSelectedLever,
    getSelectedRisk,
} from '@/services/games/game1/gameOneUtils';
import { playSound } from '@/services/global/globalHandleFunctions';
import { totalRisks } from '@/services/global/globalUtils';
import { useGame1Store } from '@/store/games/game1Store';

const RisksOrLeversList: React.FC = () => {
    const { job, risks, levers } = useGame1Store();
    const selectedRisks = getSelectedRisk();
    const selectedLevers = getSelectedLever();

    const type = job.risksValidated ? 'levers' : 'risks';

    const itemArray = type === 'levers' ? levers : risks;

    const isButtonDisabled = job.risksValidated ? !selectedLevers : !selectedRisks;

    return (
        <>
            <div className={styles.listContainer}>
                {risks.length !== totalRisks && (
                    <p className={styles.emptyList}>{globalData.tv.risksFound}</p>
                )}
                <>
                    {itemArray.map((item) => {
                        return (
                            <ClickableItem
                                key={item.id}
                                isSelected={item.isSelected}
                                description={item.name}
                                onClick={() => {
                                    handleUpdateRiskOrLeverStatus(
                                        item.id,
                                        type,
                                        'isSelected',
                                        !item.isSelected
                                    );
                                    playSound(ListItemClick);
                                }}
                                disabled={
                                    risks.length !== totalRisks ||
                                    // item.isValidated can be undefined
                                    item.isValidated ||
                                    item.isValidated === false
                                }
                                isValidated={item.isValidated}
                            />
                        );
                    })}
                </>
            </div>
            {risks.length === totalRisks && (
                <Button
                    text={`${globalData.global.validate.toUpperCase()}`}
                    color='white'
                    disabled={isButtonDisabled}
                    onClick={() =>
                        handleValidateGame1RiskOrLever(
                            job.risksValidated ? 'levers' : 'risks'
                        )
                    }
                />
            )}
        </>
    );
};

export default RisksOrLeversList;
