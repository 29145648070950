import data from '@/data/gameTwoData.json';
import {
    BADTHRESHOLD,
    getNumberOfWellRepartedJobsGame2,
    MIDTHRESHOLD,
} from '@/services/games/game2/gameTwoUtils';
import {
    handleTabletStatus,
    handleUpdateOverlayDescriptions,
} from '@/services/global/globalHandleFunctions';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { type ExposureStatus, type JobOmitGame2 } from '@/types/games/game2/types';
import { OverlayType } from '@/types/global/types';

export const handleSelectGame2Job = (currentJob: JobOmitGame2) => {
    emitSocketEvent('send_select_job_2', {
        currentJob,
    });
};

export const handleUpdateJobGame2 = (
    currentJob: JobOmitGame2,
    status: keyof typeof ExposureStatus
) => {
    if (!currentJob) return;

    emitSocketEvent('send_update_job_2', {
        jobId: currentJob.id,
        status,
    });
};

export const handleValidateGame2 = () => {
    const wellRepartedJobs = getNumberOfWellRepartedJobsGame2();

    if (wellRepartedJobs <= BADTHRESHOLD)
        handleUpdateOverlayDescriptions(data.recapOverlay.bad, OverlayType.ERROR);
    else if (wellRepartedJobs <= MIDTHRESHOLD)
        handleUpdateOverlayDescriptions(data.recapOverlay.mid, OverlayType.NEUTRAL);
    else
        handleUpdateOverlayDescriptions(
            data.recapOverlay.good,
            OverlayType.SUCCESS
        );

    handleSelectGame2Job(null as unknown as JobOmitGame2);

    // TODO: find another way
    setTimeout(() => {
        handleTabletStatus({
            blue: true,
            green: false,
            orange: false,
            red: false,
        });
    }, 200);
};
