import data from '@/data/gameOneData.json';
import InputUnlockCode from '@/features/devices/tablets/components/inputUnluckCode/InputUnlockCode';
import DigitKeypad from '@/features/devices/tablets/components/keypad/DigitKeypad';
import { handleGoToGameStatus } from '@/services/global/globalHandleFunctions';
import { useEffect, useState } from 'react';

const UnlockGameTwo = () => {
    const [code, setCode] = useState<string>('');

    useEffect(() => {
        if (code.length !== 4) return;
        if (code === data.unlockGameTwoCode) {
            handleGoToGameStatus('GAME2INTRODUCTION');
        }
        setCode('');
    }, [code]);

    return (
        <>
            <InputUnlockCode code={code} />
            <DigitKeypad setCode={setCode} />
        </>
    );
};

export default UnlockGameTwo;
