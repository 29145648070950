import dataGameFour from '@/data/gameFourData.json';
import globalData from '@/data/global.json';
import styles from '@/features/components/gameFour/gameFour.module.scss';
import JobDemandingFactorRecap from '@/features/devices/tv/components/demandingFactors/JobDemandingFactorRecap';
import TvActionHeader from '@/features/devices/tv/components/HUD/TvActionHeader';
import RecapJobRepartition from '@/features/devices/tv/components/recapJobsRepartition/RecapJobRepartition';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { isJobDemandingFactorStarted } from '@/services/games/game4/gameFourUtils';
import { useGame4Store } from '@/store/games/game4Store';
import type { PossibleJobGame4Ids } from '@/types/games/game4/types';
import { motion } from 'framer-motion';

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: { staggerChildren: 0.2, delayChildren: 0.5 },
    },
};

const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.3 } },
};

const imageVariants = {
    hidden: { opacity: 0, x: -400 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, x: 400, transition: { duration: 0.5 } },
};
const GameFour: React.FC = () => {
    const jobsDemandingFactor = useGame4Store.use.jobsGame4();
    const selectedJob = useGame4Store.use.selectedJob();
    const { currentTitle, currentText, overlayType, currentTextAudio } =
        useCurrentOverlay();

    return (
        <>
            {currentText ? (
                <RecapJobRepartition
                    audio={currentTextAudio}
                    title={currentTitle}
                    text={currentText}
                    scoreStatus={overlayType}
                />
            ) : (
                <div className={styles.container}>
                    <div className={styles.leftContainer}>
                        <div className={styles.header}>
                            <TvActionHeader
                                title='Action'
                                text={dataGameFour.guideline}
                            />
                        </div>
                        {selectedJob != null && (
                            <div className={styles.wrapper}>
                                <TvActionHeader
                                    title={
                                        selectedJob?.firstName +
                                        ' - ' +
                                        selectedJob?.name.toUpperCase()
                                    }
                                    text={selectedJob?.description}
                                    color='green'
                                    border={true}
                                    isList
                                />
                            </div>
                        )}
                    </div>
                    {selectedJob && (
                        <div className={styles.image}>
                            <motion.img
                                src={selectedJob?.fullIcon}
                                alt={selectedJob?.slug}
                                className={styles.character}
                                key={selectedJob?.id}
                                variants={imageVariants}
                                initial='hidden'
                                animate='visible'
                                exit='exit'
                            />
                        </div>
                    )}
                    <div className={styles.rightContainer}>
                        <h1>{globalData.tv.jobRepartition}</h1>
                        <motion.div
                            className={styles.wrapper}
                            variants={containerVariants}
                            initial='hidden'
                            animate='visible'
                        >
                            {jobsDemandingFactor.map((job) => {
                                if (
                                    job.id === selectedJob?.id ||
                                    isJobDemandingFactorStarted(
                                        job.id as PossibleJobGame4Ids
                                    )
                                ) {
                                    return (
                                        <motion.div
                                            key={job.id}
                                            variants={itemVariants}
                                        >
                                            <JobDemandingFactorRecap job={job} />
                                        </motion.div>
                                    );
                                }
                            })}
                        </motion.div>
                    </div>
                </div>
            )}
        </>
    );
};

export default GameFour;
