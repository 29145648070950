import dataGameThree from '@/data/gameThreeData.json';
import { getJobsData } from '@/services/global/globalUtils';
import createSelectors from '@/store/selectors';
import {
    type Argument,
    type Game3Store,
    type JobOmitGame3,
} from '@/types/games/game3/types';
import { create } from 'zustand';

export const defaultJobs3 = getJobsData(dataGameThree.jobsBelief).map((job) => ({
    ...(job as JobOmitGame3),
    isValidated: false,
}));
const initialState: Game3Store = {
    jobsGame3: defaultJobs3,
    selectedJob: null,
    selectedArgument: null,
};

const useGame3StoreBase = create(() => structuredClone(initialState));

export const useGame3Store = createSelectors(useGame3StoreBase);

export const setSelectedJobBelief = (job: JobOmitGame3) =>
    useGame3StoreBase.setState({ selectedJob: job });

export const setSelectedArgument = (argument: Argument) =>
    useGame3StoreBase.setState({ selectedArgument: argument });

export const setJobValidated = (jobId: number, isValidated: boolean) => {
    useGame3StoreBase.setState((state) => ({
        jobsGame3: state.jobsGame3.map((job) =>
            job.id === jobId ? { ...job, isValidated } : job
        ),
    }));
};

export const resetGame3 = () =>
    useGame3StoreBase.setState(structuredClone(initialState));
