import dataGameOne from '@/data/gameOneData.json';
import { getNextJobGame1 } from '@/services/games/game1/gameOneUtils';
import {
    handleGoToGameStatus,
    handleTabletStatus,
    handleUpdateOverlayDescriptions,
} from '@/services/global/globalHandleFunctions';
import {
    emitSocketEvent,
    getJobsData,
    tabletStatusEventConfig,
} from '@/services/global/globalUtils';
import { useGame1Store } from '@/store/games/game1Store';
import { type JobGame1, type Lever, type Risk } from '@/types/games/game1/types';
import { GAMESTATUS, OverlayType } from '@/types/global/types';

export const handleUpdateRisk = (risk: Risk) => {
    emitSocketEvent('send_update_risk', {
        risk,
    });
};

export const handleUpdateLevers = (levers: Lever[]) => {
    emitSocketEvent('send_update_levers', {
        levers,
    });
};

export const handleUpdateGameOneAudioJobStatus = (audioShouldPlay: boolean) => {
    emitSocketEvent('send_update_game_one_audio_job_status', {
        audioShouldPlay,
    });
};

export const handleUpdateRiskOrLeverStatus = (
    id: number,
    type: 'risks' | 'levers',
    fieldToUpdate: 'isSelected' | 'isValidated',
    value: boolean
) => {
    emitSocketEvent('send_update_risk_or_lever_selected_status', {
        id,
        type,
        fieldToUpdate,
        value,
    });
};

export function handleJobOverlay() {
    const currentJob = useGame1Store.getState().job;
    const leversFound = useGame1Store.getState().leversFound;

    const isLastJob = dataGameOne.jobs.at(-1)?.jobId === currentJob.id;

    const tabletsStatus = () => {
        if (currentJob.risksValidated && leversFound)
            return tabletStatusEventConfig.allRisksOrLeversUnlocked;
        if (currentJob.risksValidated) return tabletStatusEventConfig.risksFound;
        return tabletStatusEventConfig.allRisksOrLeversUnlocked;
    };

    handleUpdateOverlayDescriptions([], OverlayType.NEUTRAL);
    handleTabletStatus(tabletsStatus());
    handleRisksAndLeversReset('isSelected');

    // If it is not the last job
    if (currentJob.risksValidated && currentJob.leversValidated && !isLastJob) {
        const nextJob = getNextJobGame1(
            getJobsData(dataGameOne.jobs) as JobGame1[]
        );

        handleRisksAndLeversReset('isValidated');
        handleCurrentJobGame1(nextJob);
    }

    // If it is the last job
    if (isLastJob && currentJob.risksValidated && currentJob.leversValidated) {
        handleGoToGameStatus(GAMESTATUS.GAME1PREVENTION);
        handleRisksAndLeversReset('isValidated');
    }
}

export const handleValidateGame1RiskOrLever = (type: 'levers' | 'risks') => {
    const currentJob = useGame1Store.getState().job;
    const risksOrLevers = useGame1Store.getState()[type];
    const selectedRiskOrLeverId = risksOrLevers.filter((item) => item.isSelected)[0]
        .id;

    // List of good leversId or riskId for the current job
    const correctItemsId = dataGameOne.jobs.find(
        (job) => job.jobId === currentJob.id
    )![`${type}Id`];

    const responseText = currentJob[`${type}ResponseText`].filter(
        (response) => selectedRiskOrLeverId === response.id
    );

    // Transform the responseText to the overlay format
    const formattedResponseText = responseText.map((response) => {
        const isCorrect = correctItemsId.includes(selectedRiskOrLeverId);

        return {
            id: response.id,
            audio: response.audioPath,
            character: currentJob.firstName,
            text: response.responseText,
            title: isCorrect ? 'Bonne réponse' : 'Mauvaise réponse',
        };
    });

    handleTabletStatus({
        blue: true,
        red: false,
        green: false,
        orange: false,
    });

    // Update the overlay descriptions
    handleUpdateOverlayDescriptions(
        formattedResponseText,
        formattedResponseText[0].title === 'Bonne réponse'
            ? OverlayType.SUCCESS
            : OverlayType.ERROR
    );

    const isCorrect = correctItemsId.includes(selectedRiskOrLeverId);

    handleUpdateRiskOrLeverStatus(
        selectedRiskOrLeverId,
        type,
        'isValidated',
        isCorrect
    );

    // if this is the last correct item, so if all items - 1 in correctItemsId  are validated then validate the job
    if (
        risksOrLevers.filter((item) => item.isValidated).length ===
            correctItemsId.length - 1 &&
        isCorrect
    ) {
        handleCurrentJobGame1({ ...currentJob, [`${type}Validated`]: true });
    }
};

export const handleRisksOrLeversFoundStatus = (
    type: 'risks' | 'levers',
    value: boolean
) => {
    emitSocketEvent('send_update_risks_or_levers_found_status', {
        type,
        value,
    });
};

export const handleCurrentJobGame1 = (currentJob: Omit<JobGame1, 'jobId'>) => {
    emitSocketEvent('send_update_job_1', {
        currentJob,
    });
};

export const handleRisksAndLeversReset = (type: 'isSelected' | 'isValidated') => {
    emitSocketEvent('send_reset_risks_and_levers_selected_or_validated_status', {
        type,
    });
};

export const handleAllRisksFound = () => {
    handleTabletStatus(tabletStatusEventConfig.allRisksOrLeversUnlocked);
    handleRisksOrLeversFoundStatus('risks', true);
};

export const handleAllLeversFound = () => {
    handleUpdateLevers(
        dataGameOne.levers.map((lever) => ({ ...lever, isSelected: false }))
    );
    handleTabletStatus(tabletStatusEventConfig.allRisksOrLeversUnlocked);
    handleRisksOrLeversFoundStatus('levers', true);
};
