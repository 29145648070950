import { Boop } from '@/assets/sounds/soundsEffects';
import styles from '@/features/devices/tablets/components/buttons/actionButton.module.scss';

import Button from '@/features/devices/tablets/components/buttons/Button';
import { handleJobOverlay } from '@/services/games/game1/gameOneHandleFunctions';
import { handleValidateGame3 } from '@/services/games/game3/gameThreeHandleFunctions';
import {
    handleGoToGameStatus,
    handleNextOrPreviousOverlayDescription,
} from '@/services/global/globalHandleFunctions';
import { getNextGameStatus } from '@/services/global/globalUtils';
import { GAMESTATUS, type DeviceColor } from '@/types/global/types';

type ActionType =
    | 'startGame'
    | 'goToNextOverlay'
    | 'goToNextGameStatus'
    | 'goToNextPreventOverlay'
    | 'goToNextJobOverlay'
    | 'validateGame3';

type ActionHandlers = {
    [K in ActionType]: () => void;
};

interface ActionButtonProps {
    title: string;
    action: ActionType;
    style?: React.CSSProperties;
    color: DeviceColor;
    soundPath?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({
    title,
    action,
    style,
    color,
    soundPath,
}) => {
    const actionHandlers: ActionHandlers = {
        startGame: () => handleGoToGameStatus(GAMESTATUS.INTRODUCTION),
        goToNextOverlay: () => handleNextOrPreviousOverlayDescription('next'),
        goToNextJobOverlay: () => handleJobOverlay(),
        goToNextGameStatus: () => handleGoToGameStatus(getNextGameStatus()),
        goToNextPreventOverlay: () =>
            handleNextOrPreviousOverlayDescription('next'),
        validateGame3: () => handleValidateGame3(),
    };

    return (
        <div className={styles.buttonContainer} style={style}>
            <Button
                text={title}
                onClick={() => actionHandlers[action]()}
                soundPath={soundPath ?? Boop}
                color={color}
            />
        </div>
    );
};

export default ActionButton;
