import { INITIAL_GAME_STORE } from '@/sessionInfo/sessionInfo';
import createSelectors from '@/store/selectors';
import { type GameStatus } from '@/types/global/types';
import { create } from 'zustand';

const useGameStoreBase = create(() => structuredClone(INITIAL_GAME_STORE));

export const useGameStore = createSelectors(useGameStoreBase);

export const setGameStatus = (gameStatus: GameStatus) =>
    useGameStoreBase.setState({
        gameStatus,
    });

export const setIsAudioPlaying = (isAudioPlaying: boolean) =>
    useGameStoreBase.setState({
        isAudioPlaying,
    });

export const resetGame = () =>
    useGameStoreBase.setState(structuredClone(INITIAL_GAME_STORE));
