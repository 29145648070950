import { INITIAL_DEVICE_STORE } from '@/sessionInfo/sessionInfo';
import createSelectors from '@/store/selectors';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';
import { create } from 'zustand';

const useDeviceStoreBase = create(() => structuredClone(INITIAL_DEVICE_STORE));

export const useDeviceStore = createSelectors(useDeviceStoreBase);

export const setDeviceConnectedStatus = (
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
) => {
    useDeviceStoreBase.setState((state) => ({
        deviceStatus: {
            ...state.deviceStatus,
            [deviceColor]: {
                ...state.deviceStatus[deviceColor],
                isConnected: status,
            },
        },
    }));
};

export const setDeviceActivatedStatus = (
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
) => {
    useDeviceStoreBase.setState((state) => ({
        deviceStatus: {
            ...state.deviceStatus,
            [deviceColor]: {
                ...state.deviceStatus[deviceColor],
                isActivated: status,
            },
        },
    }));
};

export const resetDevice = () => {
    useDeviceStore.setState(() => ({
        deviceStatus: structuredClone({
            ...INITIAL_DEVICE_STORE.deviceStatus,
            blue: { ...INITIAL_DEVICE_STORE.deviceStatus.blue, isConnected: true },
            red: { ...INITIAL_DEVICE_STORE.deviceStatus.red, isConnected: true },
            orange: {
                ...INITIAL_DEVICE_STORE.deviceStatus.orange,
                isConnected: true,
            },
            green: {
                ...INITIAL_DEVICE_STORE.deviceStatus.green,
                isConnected: true,
            },
            tv: { ...INITIAL_DEVICE_STORE.deviceStatus.tv, isConnected: true },
            mediator: {
                ...INITIAL_DEVICE_STORE.deviceStatus.mediator,
                isConnected: true,
            },
        }),
    }));
};
