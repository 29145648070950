import createSelectors from '@/store/selectors';
import { create } from 'zustand';

export interface DeviceState {
    roomName: string;
}

const useRoomStoreBase = create(() => ({
    roomName: '',
}));

export const useRoomStore = createSelectors(useRoomStoreBase);

export const setRoomName = (roomName: string) => {
    useRoomStoreBase.setState({
        roomName,
    });
};

export const resetRoom = () => {
    useRoomStoreBase.setState({ roomName: '' });
};
