import { INITAL_OVERLAY_STORE } from '@/sessionInfo/sessionInfo';
import createSelectors from '@/store/selectors';
import { type OverlayDescription, type OverlayType } from '@/types/global/types';
import { create } from 'zustand';

const useOverlayStoreBase = create(() => structuredClone(INITAL_OVERLAY_STORE));

export const useOverlayStore = createSelectors(useOverlayStoreBase);

export const setCurrentOverlayIndex = (index: number) => {
    useOverlayStoreBase.setState({
        currentOverlayIndex: index,
    });
};

export const setCurrentOverlayDescriptions = (
    overlayDescriptions: OverlayDescription[],
    type: OverlayType
) => {
    useOverlayStoreBase.setState({
        overlayDescriptions,
        overlayType: type,
        currentOverlayIndex: 0,
    });
};

export const setCurrentOverlayType = (type: OverlayType) => {
    useOverlayStoreBase.setState({
        overlayType: type,
    });
};

export const resetOverlays = () => {
    useOverlayStoreBase.setState(structuredClone(INITAL_OVERLAY_STORE));
};
