import CreateRoom from '@/features/components/waitingPlayers/CreateRoom';
import BlueActionSwitch from '@/features/devices/tablets/blue/BlueActionSwitch';
import GreenActionSwitch from '@/features/devices/tablets/green/GreenActionSwitch';
import MediatorTablet from '@/features/devices/tablets/mediator/MediatorTablet';
import OrangeActionSwitch from '@/features/devices/tablets/orange/OrangeActionSwitch';
import RedActionSwitch from '@/features/devices/tablets/red/RedActionSwitch';
import Tv from '@/features/devices/tv/Tv';
import { isRiskFound } from '@/services/games/game1/gameOneUtils';
import { handleSelectGame4Job } from '@/services/games/game4/gameFourHandleFunctions';
import {
    handleGoToGameStatus,
    handleTabletStatus,
    handleUpdateDeviceActivatedStatus,
} from '@/services/global/globalHandleFunctions';
import {
    emitSocketEvent,
    isDevelopperMode,
    tabletStatusEventConfig,
} from '@/services/global/globalUtils';
import SocketService from '@/services/SocketServices';
import {
    resetDevice,
    setDeviceActivatedStatus,
    setDeviceConnectedStatus,
} from '@/store/deviceStore';
import {
    resetGame1,
    resetRisksAndLeversSelectedOrValidatedStatus,
    setAudioShouldPlay,
    setJobGame1,
    setLevers,
    setRiskOrLeversFoundStatus,
    setRisks,
    setRiskOrLeverSelectedOrValidatedStatus,
} from '@/store/games/game1Store';
import {
    resetGame2,
    setExposureStatus,
    setSelectedJobExposure,
} from '@/store/games/game2Store';
import {
    resetGame3,
    setJobValidated,
    setSelectedArgument,
    setSelectedJobBelief,
} from '@/store/games/game3Store';
import {
    resetCurrentAnswers,
    resetGame4,
    setCurrentAnswers,
    setSelectedJobDemandingFactor,
} from '@/store/games/game4Store';
import { resetGame, setGameStatus, setIsAudioPlaying } from '@/store/gameStore';
import {
    resetOverlays,
    setCurrentOverlayDescriptions,
    setCurrentOverlayIndex,
    setCurrentOverlayType,
} from '@/store/overlayStore';
import { setRoomName } from '@/store/roomStore';
import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
    GAMESTATUS,
    type DeviceColorWithTvAndMediator,
} from '@/types/global/types';

const App: React.FC = () => {
    const [room, setRoom] = useState('');
    const socket = SocketService.getInstance().getSocket();
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        if (!isFirstRender) {
            return;
        }
        const currentDevice = window.location.pathname
            .split('/')[1]
            .split('-')[0] as DeviceColorWithTvAndMediator;

        if (!isDevelopperMode) return;

        setRoomName('devRoom');
        setDeviceConnectedStatus(currentDevice, true);
        setRoom('devRoom');
        emitSocketEvent('send_join_room', {
            device: currentDevice,
        });
        setIsFirstRender(false);
    }, [isFirstRender]);

    useEffect(() => {
        socket.on('receive_start_game', (data) => {
            setDeviceConnectedStatus(data.color, true);
            if (data.color === 'mediator') {
                handleUpdateDeviceActivatedStatus(data.color, true);
                handleGoToGameStatus(GAMESTATUS.WELCOME_SCREEN);
            }
        });
        socket.on(
            'receive_update_game_one_audio_job_status',
            (data: { audioShouldPlay: boolean }) => {
                setAudioShouldPlay(data.audioShouldPlay);
            }
        );
        socket.on('receive_game_status', (data) => {
            setGameStatus(data.status);
        });
        socket.on('receive_device_activated_status', (data) => {
            setDeviceActivatedStatus(
                data.deviceColor as DeviceColorWithTvAndMediator,
                data.status
            );
        });
        socket.on('receive_reset', () => {
            resetOverlays();
            resetDevice();
            resetGame();
            resetGame1();
            resetGame2();
            resetGame3();
            resetGame4();
        });
        socket.on('receive_is_audio_playing', (data) => {
            setIsAudioPlaying(data.isAudioPlaying);
        });
        socket.on('receive_update_overlay_index', (data) => {
            setCurrentOverlayIndex(data.overlayIndex);
        });
        socket.on('receive_update_overlay_descriptions', (data) => {
            setCurrentOverlayDescriptions(
                data.overlayDescriptions,
                data.overlayType
            );
        });
        socket.on('receive_update_overlay_type', (data) => {
            setCurrentOverlayType(data.overlayType);
        });
        socket.on('receive_update_risk', (data) => {
            if (isRiskFound(data.risk)) return;
            setRisks(data.risk);
        });
        socket.on('receive_update_levers', (data) => {
            setLevers(data.levers);
        });
        socket.on('receive_update_risk_or_lever_selected_status', (data) => {
            setRiskOrLeverSelectedOrValidatedStatus(
                data.id,
                data.type,
                data.fieldToUpdate,
                data.value
            );
        });
        socket.on('receive_update_risks_or_levers_found_status', (data) => {
            setRiskOrLeversFoundStatus(data.type, data.value);
        });
        socket.on(
            'receive_reset_risks_and_levers_selected_or_validated_status',
            (data) => {
                resetRisksAndLeversSelectedOrValidatedStatus(data.type);
            }
        );
        socket.on('receive_update_job_1', (data) => {
            setJobGame1(data.currentJob);
        });
        socket.on('receive_select_job_2', (data) => {
            setSelectedJobExposure(data.currentJob);
            handleTabletStatus(tabletStatusEventConfig.headEmployeGame2Selected);
        });
        socket.on('receive_select_job_3', (data) => {
            setSelectedJobBelief(data.currentJob);
        });
        socket.on('receive_select_job_4', (data) => {
            setSelectedJobDemandingFactor(data.currentJob);
        });
        socket.on('receive_select_argument', (data) => {
            setSelectedArgument(data.selectedArgument);
        });
        socket.on('receive_update_job_2', (data) => {
            setExposureStatus(data.jobId, data.status);
        });
        socket.on('receive_update_job_3_validation_status', (data) => {
            setJobValidated(data.jobId, data.isValidated);
        });
        socket.on('receive_select_current_answer', (data) => {
            setCurrentAnswers(data.selectedJobId, data.index, data.nextStatus);
        });
        socket.on('receive_reset_current_answers', () => {
            handleSelectGame4Job(null);
            resetCurrentAnswers();
        });

        return () => {
            socket.off('receive_start_game');
            socket.off('receive_update_game_one_audio_job_status');
            socket.off('receive_game_status');
            socket.off('receive_device_activated_status');
            socket.off('receive_reset');
            socket.off('receive_is_audio_playing');
            socket.off('receive_update_overlay_index');
            socket.off('receive_update_overlay_descriptions');
            socket.off('receive_update_overlay_type');
            socket.off('receive_update_risk');
            socket.off('receive_update_levers');
            socket.off('receive_update_risk_or_lever_selected_status');
            socket.off('receive_update_risks_or_levers_found_status');
            socket.off(
                'receive_reset_risks_and_levers_selected_or_validated_status'
            );
            socket.off('receive_update_job_1');
            socket.off('receive_select_job_2');
            socket.off('receive_select_job_3');
            socket.off('receive_select_job_4');
            socket.off('receive_select_argument');
            socket.off('receive_update_job_2');
            socket.off('receive_update_job_3_validation_status');
            socket.off('receive_select_current_answer');
            socket.off('receive_reset_current_answers');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const router = createBrowserRouter([
        {
            path: '/tv',
            element: room ? <Tv /> : <CreateRoom color='tv' setRoom={setRoom} />,
        },
        {
            path: '/red-tablet',
            element: room ? (
                <RedActionSwitch />
            ) : (
                <CreateRoom color='red' setRoom={setRoom} />
            ),
        },
        {
            path: '/blue-tablet',
            element: room ? (
                <BlueActionSwitch />
            ) : (
                <CreateRoom color='blue' setRoom={setRoom} />
            ),
        },
        {
            path: '/green-tablet',
            element: room ? (
                <GreenActionSwitch />
            ) : (
                <CreateRoom color='green' setRoom={setRoom} />
            ),
        },
        {
            path: '/orange-tablet',
            element: room ? (
                <OrangeActionSwitch />
            ) : (
                <CreateRoom color='orange' setRoom={setRoom} />
            ),
        },
        {
            path: '/mediator-tablet',
            element: room ? (
                <MediatorTablet roomName={room} />
            ) : (
                <CreateRoom color='mediator' setRoom={setRoom} />
            ),
        },
    ]);

    return <RouterProvider router={router} />;
};

export default App;
