import globalData from '@/data/global.json';
import WaitingTabletFooter from '@/features/devices/tv/components/HUD/WaitingTabletFooter';
import DisplayJobs from '@/features/devices/tv/components/jobs/DisplayJobs';
import {
    getSelectedLever,
    getSelectedRisk,
} from '@/services/games/game1/gameOneUtils';
import { type DeviceColor } from '@/types/global/types';

interface ErrorAndSuccessScreenProps {
    title: string;
    text: string;
    color?: DeviceColor;
    audio?: string;
}

const ErrorAndSuccessScreen: React.FC<ErrorAndSuccessScreenProps> = ({
    color,
    title,
    text,
    audio,
}) => {
    const selectedRisk = getSelectedRisk();
    const selectedLever = getSelectedLever();

    const risksOrLever = selectedLever ? selectedLever : selectedRisk;

    return (
        <>
            <DisplayJobs
                color={color}
                title={`${risksOrLever?.name} - ${title}`}
                text={text}
                audio={audio}
            />
            <WaitingTabletFooter tablet={globalData.global.blue} />
        </>
    );
};

export default ErrorAndSuccessScreen;
